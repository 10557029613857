.solution-wrap {
    min-height: 100vh;
    width: 100vw;
    background-color: beige;
    background-image: url('../../assets/images/background/home-bg.webp');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin-top: -200px;
    padding-bottom: 400px;

    @media only screen and (max-width: 1024px) {
        padding-bottom: 300px;
    }

    .solution-upper-mobile-box{
        background-color: #D6B07F;
        height: 150px;
        width: 50vw;
        margin-top: 200px;
    }

    .solution-upper-content-wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 155px;
        margin-bottom: 30px;
        z-index: 3;
        position: relative;

        @media only screen and (max-width: 769px) {
            margin-top: -45px;
        }

        .solution-upper-img-wrap {
            height: 250px;

            .solution-clip-img {
                height: 100%;
                width: 100%;
                object-fit: contain;
            }
        }

        .solution-lower-img-wrap {
            height: 380px;

            @media only screen and (max-width: 426px) {
                height: 250px;
            }

            @media only screen and (max-width: 376px) {
                height: 200px;
            }
            
            @media only screen and (max-width: 320px) {
                height: 150px;
            }

            .solution-logo-img {
                height: 100%;
                width: 100%;
                object-fit: contain;
            }
        }
    }

    .solution-mobile-wrap{
        flex-direction: column !important;
    }

    .solution-lower-content-wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: space-around;

        .solution-textbox {
            padding: 30px 50px;
            max-width: 230px;
            min-width: 230px;
            font-family: sans-serif;

            @media only screen and (max-width: 376px) {
                max-width: 250px;
            }
            

            .solution-title {
                display: flex;
                align-items: center;

                .solution-title-clip {
                    height: 40px;
                    width: 40px;
                }

                .solution-title-text {
                    font-size: 20px;
                    color: #1D39C4;
                    font-weight: 700;
                    margin-left: -25px;
                }
            }

            .solution-content {
                font-size: 14px;
                margin-left: 15px;
                margin-top: 15px;
            }
        }
    }
}