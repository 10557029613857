.pricing-wrap {
    min-height: 100vh;
    width: 100vw;
    background-color: white;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .pricing-content-wrap {
        z-index: 3;

        .pricing-clip-img-wrap {
            display: flex;
            flex-direction: column;
            align-items: center;

            .pricing-clip-img {
                height: 250px;
                width: 85px;
            }
        }

        .pricing-title-wrap {
            display: flex;
            align-items: center;
            justify-content: center;

            span {
                font-size: 90px;
                font-weight: 700;
                font-family: sans-serif;

                @media only screen and (max-width: 576px) {
                    font-size: 50px;
                }

                @media only screen and (max-width: 426px) {
                    font-size: 40px;
                }

                @media only screen and (max-width: 300px) {
                    font-size: 30px;
                }
            }
        }

        .pricing-box-wrap {
            display: flex;
            justify-content: center;
            margin-top: 70px;

            @media only screen and (max-width: 768px) {
                margin-top: 70px;
                flex-direction: column;
                align-items: center;
            }

            .pricing-box:last-child {
                margin-left: 40px;

                @media only screen and (max-width: 768px) {
                    margin-left: unset;
                    margin-top: 60px;
                }
            }

            .fee-box:hover {
                .pricing-content-title {
                    background-color: #D6B07F;
                    color: white;

                    .pricing-content-title-img {
                        .pricing-title-img {
                            visibility: visible;
                        }
                    }
                }
            }

            .limit-box:hover {
                .pricing-content-title {
                    background-color: #1D39C4;
                    color: white;

                    .pricing-content-title-img {
                        .pricing-title-img {
                            visibility: visible;
                        }
                    }
                }
            }

            .pricing-box {
                cursor: pointer;
                height: 450px;
                width: 400px;
                border-radius: 25px;
                box-shadow: 0 0 2px #fff, 0 0 10px #fff, 0 0 20px #16020222, 0 0 30px #0000002d;

                @media only screen and (max-width: 426px) {
                    height: 450px;
                    width: 350px;
                }

                @media only screen and (max-width: 376px) {
                    width: 320px;
                }

                @media only screen and (max-width: 320px) {
                    width: 270px;
                }

                @media only screen and (max-width: 300px) {
                    width: 250px;
                }

                hr {
                    margin: 0;
                    border-color: #E5E4E2;
                }

                .pricing-content-title {
                    border-top-right-radius: 25px;
                    border-top-left-radius: 25px;
                    display: flex;
                    align-items: flex-start;
                    justify-content: space-between;

                    @media only screen and (max-width: 426px) {
                        align-items: center;
                    }

                    .pricing-content-title-text {
                        padding-top: 30px;
                        padding-left: 30px;

                        @media only screen and (max-width: 426px) {
                            padding-top: 20px;
                            padding-left: 20px;
                        }

                        @media only screen and (max-width: 320px) {
                            padding-bottom: 10px;
                        }
                    }

                    .pricing-content-title-img {
                        margin-right: 10px;

                        .pricing-title-img {
                            height: 85px;
                            visibility: hidden;

                            @media only screen and (max-width: 376px) {
                                height: 65px;
                            }

                            @media only screen and (max-width: 320px) {
                                height: 55px;
                            }

                            @media only screen and (max-width: 300px) {
                                height: 40px;
                            }
                        }
                    }

                    span {
                        font-size: 30px;
                        font-weight: 700;
                        font-family: sans-serif;

                        @media only screen and (max-width: 426px) {
                            font-size: 24px;
                        }
                    }
                }

                .pricing-content-box {
                    padding: 15px;

                    @media only screen and (max-width: 320px) {
                        padding-top: 15px;
                        padding-left: unset;
                        padding-right: unset;
                    }

                    .pricing-text-box:first-child {
                        margin-top: 10px;
                    }

                    .pricing-text-box {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        padding: 0px 20px 0px 20px;
                        margin-bottom: 15px;

                        .pricing-text-img {
                            height: 40px;
                            width: 40px;
                        }
                    }

                    .pricing-text {
                        margin-left: 15px;
                        font-size: 14px;
                    }

                    .pricing-text:last-child {
                        margin-bottom: unset;
                    }

                    .highlight-text {
                        color: #1D39C4;
                        font-weight: 700;
                    }
                }
            }
        }

        .pricing-bottom-content-wrap {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-right: -100px;
            margin-bottom: -5px;
            margin-top: 50px;

            @media only screen and (max-width: 1024px) {
                margin-right: -50px;
            }

            @media only screen and (max-width: 768px) {
                margin-right: unset;
                flex-direction: column;
            }

            .pricing-bottom-text {
                @media only screen and (max-width: 426px) {
                    height: 120px;
                }

                @media only screen and (max-width: 320px) {
                    height: 100px;
                }
            }

            .pricing-bottom-logo-wrap {
                @media only screen and (max-width: 768px) {
                    margin-top: 100px;
                }

                .pricing-bottom-logo {
                    height: 350px;

                    @media only screen and (max-width: 426px) {
                        height: 280px;
                    }

                    @media only screen and (max-width: 376px) {
                        height: 250px;
                    }

                    @media only screen and (max-width: 376px) {
                        height: 220px;
                    }
                }
            }
        }
    }
}