.works-wrap {
    min-height: 100vh;
    width: 100vw;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    height: 950px;

    .works-overlay-wrap {
        min-height: 400px;
        min-width: 400px;
        position: absolute;
        z-index: 5;
        margin-top: -100px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .works-overlay-upper-content {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-bottom: 100px;
            padding-left: 80px;

            @media only screen and (max-width: 768px) {
                padding-left: unset;
                margin-bottom: 80px;
            }

            @media only screen and (max-width: 426px) {
                justify-content: center;
                padding-left: 5px;
                padding-right: 5px;
            }

            .works-clip-logo {
                height: 80px;

                @media only screen and (max-width: 376px) {
                    height: 70px;
                }

                @media only screen and (max-width: 320px) {
                    height: 55px;
                }

                @media only screen and (max-width: 300px) {
                    height: 50px;
                }

                .works-clip-img {
                    height: 100%;
                    width: 100%;
                    object-fit: contain;
                }
            }

            .works-title-logo {
                margin-left: 15px;
                height: 70px;

                @media only screen and (max-width: 376px) {
                    height: 60px;
                }

                @media only screen and (max-width: 320px) {
                    height: 55px;
                }

                @media only screen and (max-width: 300px) {
                    height: 50px;
                }

                .works-logo-img {
                    height: 100%;
                    width: 100%;
                    object-fit: contain;
                }
            }
        }

        .works-overlay-lower-content {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            .works-content-img-mb-1 {
                margin-bottom: 60px;

                @media only screen and (max-width: 320px) {
                    height: 200px;
                }

                @media only screen and (max-width: 300px) {
                    height: 180px;
                }
            }

            .works-content-img-mb-2 {
                @media only screen and (max-width: 768px) {
                    height: 400px;
                }

                @media only screen and (max-width: 320px) {
                    height: 350px;
                }

                @media only screen and (max-width: 300px) {
                    height: 320px;
                    margin-top: 50px;
                }
            }

            .works-content-img {
                height: 80%;
                width: 80%;
                margin-right: 15px;

                @media only screen and (max-width: 1024px) {
                    height: 65%;
                    width: 65%;
                }
            }
        }
    }

    .works-left-content-wrap {
        background-color: #262626;
        width: calc(100% - 10%);
        z-index: 3;
        height: 750px;
        padding-bottom: 230px;
        margin-top: -200px;
        position: absolute;
        left: 0;

        @media only screen and (max-width: 1024px) {
            padding-bottom: 150px;
        }

        @media only screen and (max-width: 768px) {
            width: 100vw;
            height: 900px;
        }

        @media only screen and (max-width: 320px) {
            height: 800px;
        }
    }

    .works-right-content-wrap {
        background-color: #1D39C4;
        width: calc(100% - 50%);
        height: 1000px;
        position: absolute;
        right: 0;
    }
}