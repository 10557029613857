.content-wrap{
    max-width: 1600px;
    min-width: 1280px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.overall-wrap{
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}