.usdt-prob-wrap {
    min-height: 100vh;
    width: 100vw;
    background-color: #D6B07F;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: -140px;

    .usdt-vertical-title-wrap {
        height: auto;
        width: 100px;
        text-orientation: mixed;
        writing-mode: vertical-lr;
        padding-top: 500px;
        min-height: 500px;

        @media only screen and (max-width: 768px) {
            display: none;
        }

        .usdt-vertical-title {
            font-size: 120px;
            font-weight: 700;
            color: rgba(255, 255, 255, 0.4);
            font-family: sans-serif;

            @media only screen and (max-width: 1024px) {
                font-size: 100px;
            }
        }
    }

    .usdt-prob-content-box {
        background-image: url('../../assets/images/background/problems-bg.webp');
        background-size: contain;
        min-height: 1200px;
        width: 75vw;
        position: relative;
        right: 0;
        background-color: #262626;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        background-repeat: no-repeat;
        background-position: bottom;
        flex-direction: column;
        margin-top: 140px;

        @media only screen and (max-width: 1024px) {
            width: 80vw;
        }

        @media only screen and (max-width: 768px) {
            width: 100vw;
            flex-direction: column;
            padding: 50px 0px;
            align-items: center;
        }

        @media only screen and (max-width: 576px) {
            padding: 50px 0px;
        }

        @media only screen and (max-width: 426px) {
            padding: 50px 0px;
            min-height: 900px
        }

        @media only screen and (max-width: 376px) {
            padding: 50px 0px;
            min-height: 900px
        }

        @media only screen and (max-width: 300px) {
            padding: 50px 0px;
            min-height: 900px
        }

        .usdt-side-logo {
            min-width: 350px;
            min-height: 50px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-top: 90px;
            margin-left: -40px;

            @media only screen and (max-width: 768px) {
                margin-top: unset;
                margin-bottom: 50px;
                justify-content: center;
                margin-left: unset;
            }

            @media only screen and (max-width: 376px) {
                min-width: 250px;
            }

            .usdt-side-logo-position {
                .usdt-clip-img {
                    height: 100%;
                    width: 100%;
                    max-height: 80%;
                    max-width: 80%;

                    @media only screen and (max-width: 376px) {
                        max-height: 60%;
                        max-width: 60%;
                    }

                    @media only screen and (max-width: 300px) {
                        max-height: 70%;
                        max-width: 70%;
                    }
                }
            }

            .usdt-title-text {
                font-size: 26px;
                font-weight: 700;
                font-family: sans-serif;
                margin-left: -15px;
                color: #FFFFFF;

                @media only screen and (max-width: 426px) {
                    font-size: 24px;
                    margin-left: -30px;
                }

                @media only screen and (max-width: 300px) {
                    font-size: 20px;
                    margin-left: -10px;
                }
            }
        }

        .usdt-content-box {
            min-height: 300px;
            width: 100%;
            display: flex;
            justify-content: flex-start;
            padding: 60px 120px;

            @media only screen and (max-width: 1024px) {
                padding: 30px 60px;
            }

            @media only screen and (max-width: 768px) {
                padding: unset;
                justify-content: center;
            }

            .box-02 {
                margin-top: 70px;
            }

            .box-03 {
                margin-top: 240px;
            }

            .usdt-content-wrap {
                display: flex;
                flex-direction: column;
                margin-right: 50px;

                @media only screen and (max-width: 768px) {
                    margin-left: 30px;
                    margin-right: 30px;
                }

                @media only screen and (max-width: 576px) {
                    margin-left: 10px;
                    margin-right: 10px;
                }

                @media only screen and (max-width: 426px) {
                    margin-left: 4px;
                    margin-right: 4px;
                }

                @media only screen and (max-width: 300px) {
                    margin-left: 3px;
                    margin-right: 3px;
                }
            }

            .prob-content-box:hover {
                color: black;
                background-color: #ffffff;
                transition: 0.3s ease-in-out;
            }

            .prob-content-box {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                color: white;
                background-color: rgba(255, 255, 255, 0.2);
                height: 280px;
                width: 230px;
                border-radius: 25px;
                box-shadow: 0 0 2px #fff, 0 0 10px #fff, 0 0 20px #ffb1232e, 0 0 30px #ffa7235e, 0 0 40px #ffa72361, 0 0 50px #ffa7233b;
                margin-bottom: 30px;
                cursor: pointer;

                @media only screen and (max-width: 1024px) {
                    height: 250px;
                    width: 210px;
                }

                @media only screen and (max-width: 576px) {
                    height: 200px;
                    width: 180px;
                }

                @media only screen and (max-width: 426px) {
                    height: 200px;
                    width: 170px;
                }

                @media only screen and (max-width: 376px) {
                    height: 200px;
                    width: 150px;
                }

                @media only screen and (max-width: 320px) {
                    height: 200px;
                    width: 130px;
                }

                @media only screen and (max-width: 300px) {
                    height: 200px;
                    width: 120px;
                }

                .prob-content-img {
                    @media only screen and (max-width: 1024px) {
                        height: 100px;
                    }

                    @media only screen and (max-width: 576px) {
                        height: 80px;
                    }

                    @media only screen and (max-width: 376px) {
                        height: 80px;
                    }

                    @media only screen and (max-width: 300px) {
                        height: 60px;
                    }
                }

                .prob-content-wrap {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    padding: 0px 30px;
                    font-family: sans-serif;
                    text-align: center;

                    @media only screen and (max-width: 576px) {
                        padding: 0px 10px;
                    }

                    h2 {
                        font-size: 20px;

                        @media only screen and (max-width: 426px) {
                            margin-top: 3px;
                            margin-bottom: 3px;
                        }
                    }

                    span {
                        font-size: 14px;

                        @media only screen and (max-width: 576px) {
                            font-size: 12px;
                        }
                    }
                }
            }

        }
    }
}