body,
html {
  float: none !important;
  position: relative;
  scroll-behavior: smooth;
  width: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: clip;
}

.header-gap {
  margin-top: 120px;
}

.menu-active {
  text-decoration: underline;
  text-decoration-color: #0036B9;
  text-decoration-thickness: 2px;
  text-underline-offset: 8px;
}

.menu-active-white {
  text-decoration: underline;
  text-decoration-color: #ffffff;
  text-decoration-thickness: 2px;
  text-underline-offset: 8px;
}
