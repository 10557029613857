.home-wrap {
    height: 100vh;
    width: 100vw;
    background-image: url('../../assets/images/background/home-bg.webp');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;

    .home-logo-wrap {
        height: 80%;

        @media only screen and (max-width: 1024px) {
            height: 85%;
        }

        @media only screen and (max-width: 768px) {
            height: 70%;
        }

        @media only screen and (max-width: 426px) {
            height: 60%;
        }

        @media only screen and (max-width: 376px) {
            height: 55%;
        }

        @media only screen and (max-width: 320px) {
            height: 50%;
        }

        @media only screen and (max-width: 300px) {
            height: 45%;
        }

        .home-logo-img {
            object-fit: contain;
            height: 100%;
            width: 100%;
        }
    }
}

.mbButtonContainer {
    position: fixed;
    top: 3%;
    z-index: 99999 !important;
    right: 3%;
}

.buttonStyle {
    border: none;
    border-radius: 100%;
    display: inline-flex;
    width: 40px;
    padding: 10px;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.backdropMenu {
    height: 100vh;
    width: 100%;
    position: fixed;
    background-color: #0036B9;
    color: white;
    z-index: 99999;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.menuList {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    top: 50%;
}

.textButton {
    cursor: pointer;
    font-weight: 600;
}

.logoImageSmall{
    margin-bottom: 15px;
}