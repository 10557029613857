.language-switcher-container {
    display: flex;
    align-items: center;

    p span:not(:first-child) {
        border-left: 1px solid #000;
    }

    p span{
        cursor: pointer;
        padding: 0px 8px;
    }
}

.language-switcher-divider {
    margin: 0;
    background-color: #d9d9d9;
}

.language-dropdown .ant-select-selector {
    width: 80px !important;
    color: #808080;
    border: 1px solid#808080 !important;
}

.language-dropdown .ant-select-arrow {
    color: #808080;
}