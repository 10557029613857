.about-wrap {
    min-height: 100vh;
    width: 100vw;
    background-color: beige;
    background-image: url('../../assets/images/background/home-bg.webp');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;

    .about-text-wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        max-width: 700px;

        .about-title {
            font-size: 30px;
            font-weight: 700;
            font-family: sans-serif;
            margin-bottom: 30px;

            @media only screen and (max-width: 300px) {
                font-size: 25px;
            }
        }

        .about-content {
            text-align: center;
            margin-bottom: 60px;
            padding-left: 10px;
            padding-right: 10px;
        }

        .about-logo-wrap {
            height: 600px;

            @media only screen and (max-width: 1024px) {
                height: 500px;
            }

            @media only screen and (max-width: 768px) {
                height: 500px;
            }

            @media only screen and (max-width: 426px) {
                height: 400px;
            }

            @media only screen and (max-width: 376px) {
                height: 300px;
            }

            .about-logo-img {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }
    }
}

.about-content-wrap {
    margin-top: -10px;
    height: 620px;
    width: 100vw;
    display: flex;
    justify-content: space-between;

    .about-vertical-title-wrap {
        height: auto;
        width: 100px;
        text-orientation: mixed;
        writing-mode: vertical-lr;

        @media only screen and (max-width: 768px) {
            display: none;
        }

        .about-vertical-title {
            font-size: 120px;
            font-weight: 700;
            color: rgba(29, 57, 196, 0.2);
            font-family: sans-serif;

            @media only screen and (max-width: 1024px) {
                font-size: 100px;
            }
        }
    }

    .about-content-box {
        min-height: 300px;
        width: 75vw;
        position: relative;
        right: 0;
        background-color: white;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;

        @media only screen and (max-width: 1024px) {
            width: 80vw;
        }

        @media only screen and (max-width: 768px) {
            width: 100vw;
            flex-direction: column;
            padding: 50px 0px;
            align-items: center;
        }

        @media only screen and (max-width: 576px) {
            padding: 50px 0px;
        }

        @media only screen and (max-width: 426px) {
            padding: 50px 0px;
        }

        @media only screen and (max-width: 300px) {
            padding: 50px 0px;
        }

        .about-side-logo {
            min-width: 350px;
            min-height: 50px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-top: 90px;
            margin-left: -40px;

            @media only screen and (max-width: 768px) {
                margin-top: unset;
                margin-bottom: 50px;
                justify-content: center;
                margin-left: unset;
            }

            @media only screen and (max-width: 376px) {
                min-width: 250px;
            }

    
            .about-title-text {
                font-size: 26px;
                font-weight: 700;
                font-family: sans-serif;
                margin-left: -30px;

                @media only screen and (max-width: 376px) {
                    margin-left: -50px;
                    font-size: 22px;
                }
            }

            .about-clip-img {
                height: 100%;
                width: 100%;
                max-height: 80%;
                max-width: 80%;

                @media only screen and (max-width: 376px) {
                    max-height: 60%;
                    max-width: 60%;
                }
            }
        }

        .about-side-content {
            height: 100%;
            width: 100%;
            margin-top: 90px;
            margin-left: 50px;
            margin-bottom: 90px;

            @media only screen and (max-width: 768px) {
                margin-top: unset;
                margin-left: unset;
                margin-bottom: unset;
                display: flex;
                flex-direction: column;
                align-items: center;
            }

            .about-side-content-list {
                padding: 0px 10px;

                .about-content-list {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    font-family: sans-serif;
                    max-width: 400px;
                    margin-bottom: 35px;

                    @media only screen and (max-width: 320px) {
                        max-width: 300px;
                    }

                    @media only screen and (max-width: 280px) {
                        max-width: 250px;
                    }

                    .about-content-count {
                        .about-number-img {
                            height: 50px;
                            width: 50px;

                            @media only screen and (max-width: 300px) {
                                height: 35px;
                                width: 35px;
                            }
                        }
                    }

                    .about-content-info {
                        margin-left: 30px;
                        font-size: 14px;

                        @media only screen and (max-width: 300px) {
                            margin-left: 10px;
                        }
                    }
                }

            }
        }
    }
}

.header-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    background-color: #0036B9;
    height: 95px;
    color: white;
    font-size: 14px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    transition: 0.3s ease-in-out all;
    z-index: 999;
    font-weight: 600;

    .header-spacing{
        width: 80px;

        @media only screen and (max-width: 1024px) {
            width: 40px;
        }
    }

    @media only screen and (max-width: 768px) {
        display: none;
    }

    .header-logo {
        cursor: pointer;
        margin-right: 18px;
    }

    .menu-wrap {
        margin: 0px 18px;
        cursor: pointer;

        @media only screen and (max-width: 1024px) {
            margin: 0px 5px;
        }
    }
}

.transparent-header {
    backdrop-filter: blur(20px);
    background-color: #FFFFFF90;
    color: black;
}

.sticky {
    position: sticky;
    top: 0;
    width: 100vw;
}