.footer-wrap {
    width: 100vw;
    background-color: #D6B07F;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    padding-top: 60px;

    @media only screen and (max-width: 768px) {
        flex-direction: column;
        padding-top: unset;
    }

    .footer-left {
        background-color: transparent;
        min-width: 100px;
        min-height: 100px;
        width: calc(100% - 65%);
        height: calc(100% - 25%);
        padding-right: 20px;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: center;

        @media only screen and (max-width: 768px) {
            height: 150px;
            width: 100%;
            padding-right: unset;
        }
    }

    .footer-right {
        background-color: white;
        min-width: 100px;
        min-height: 100px;
        width: calc(100% - 35%);
        // height: calc(100% - 25%);
        height: calc(100vh - 25vh);

        @media only screen and (max-width: 768px) {
            width: 100%;
            height: 510px;
        }
    }

    .footer-title-box {
        height: 150px;
        background-color: transparent;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        @media only screen and (max-width: 768px) {
            justify-content: center;
            margin-left: unset;
            padding: 0px 10px;
        }

        .footer-title-box-wrap {
            height: 60px;
            margin-right: 15px;
        }


        .footer-clip-img {
            height: 100%;
            width: 100%;
            object-fit: contain;
        }

        .footer-title-text {
            font-size: 45px;
            font-weight: 700;
            font-family: sans-serif;

            @media only screen and (max-width: 1024px) {
                font-size: 32px;
            }

            @media only screen and (max-width: 320px) {
                font-size: 22px;
            }
        }
    }

    .footer-logo-box {
        background-color: transparent;
        z-index: 2;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        margin-right: -150px;
        margin-bottom: -10px;


        @media only screen and (max-width: 768px) {
            display: none;
        }

        .footer-logo-img {
            height: 100%;
            width: 100%;
            object-fit: contain;
        }

    }

    .footer-contact-box {
        padding: 80px 20% 0px 20%;

        @media only screen and (max-width: 426px) {
            padding: 80px 15% 0px 15%;
        }

        @media only screen and (max-width: 376px) {
            padding: 80px 10% 0px 10%;
        }

        .footer-contact-box-wrap {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        .footer-contact-wrap {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            max-width: 300px;
            min-height: 100px;
            min-width: 300px;
            margin: 15px 0px;

            .footer-contact-info-logo {
                .footer-contact-logo-img {

                    @media only screen and (max-width: 426px) {
                        height: 65px;
                    }
                }
            }

            .footer-contact-info {
                margin-left: 30px;

                @media only screen and (max-width: 320px) {
                    margin-left: 15px;
                }

                .footer-contact-info-title {
                    color: #1D39C4;
                    font-size: 20px;
                    font-weight: 700;

                    @media only screen and (max-width: 426px) {
                        font-size: 18px;
                    }
                }

                .footer-contact-info-content {
                    font-size: 14px;
                }
            }
        }
    }
}

.footer-copyright-box {
    width: 100vw;
    height: 80px;
    background-color: #262626;
    color: #F8E5C2;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    font-family: sans-serif;
    z-index: 3;

    @media only screen and (max-width: 576px) {
        flex-direction: column;
    }

    .footer-tnc-box {
        display: flex;
        align-items: center;

        div {
            cursor: pointer;
        }

        .footer-hr {
            height: 0.7rem;
            margin: 0px 5px;
        }
    }
}